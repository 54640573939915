import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import Usage from "./components/Usage";
import BadRequest from "./components/BadRequest";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
const routing = (
  <Router>
    <Switch>
      <Route exact path="/" component={Usage} />
      <Route exact path="/:system/:run" render={props => <App {...props} />} />
      <Route
        exact
        path="/:system/:run/:hostname"
        render={props => <App {...props} />}
      />
      <Route path="/*" component={BadRequest} />
    </Switch>
  </Router>
);

ReactDOM.render(routing, document.getElementById("root"));
