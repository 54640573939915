// React Imports
import React, { Component } from "react";

// 3rd Party Package Imports
import "bootstrap/dist/css/bootstrap.min.css";

// JSV Project Imports
import "../App.css";
import HardwareThread from "./HardwareThread";

export default class CPU extends Component {
  render() {
    let resourceSetColors = [
      "#e6194B",
      "#F1FF00",
      "#00FFF6",
      "#FE00FF",
      "#FF8300",
      "#08FF00",
      "#4363d8",
      "#911eb4",
      "#bfef45",
      "#de38a4",
      "#41953a",
      "#e273d4",
      "#cae26c",
      "#ac55a9",
      "#8fa238",
      "#6183d5",
      "#de9730",
      "#7face1",
      "#e44931",
      "#54e3c8",
      "#e53769",
      "#72c682",
      "#d25f8d",
      "#419876",
      "#cc645d",
      "#5dc7d8",
      "#cc7137",
      "#4a8a9e",
      "#967e2c",
      "#db9edb",
      "#618242",
      "#9a72a3",
      "#c7e29a",
      "#767ea0",
      "#e4b979",
      "#b0d0dc",
      "#a17a57",
      "#99d7c0",
      "#e79999",
      "#738976",
      "#d4b8d2",
      "#a2a772",
      "#a27479",
      "#d9ceb4"
    ];
    let style = {
      boxShadow: "0 0 0 3px black"
    };
    let opacityStyle = {
      opacity: `0.3`
    };

    const { cpuId, resourceSetId, data, hostname } = this.props;
    let fourHardwareThreads = [];
    let start = cpuId * 4;

    for (let i = 0; i < 4; i++) {
      let id = start + i;
      fourHardwareThreads.push(
        <HardwareThread
          threadID={id}
          data={data}
          hostname={hostname}
          index={i}
          cpuId={cpuId}
        />
      );
    }
    if (resourceSetId >= 0) {
      style.boxShadow = "0 0 0 3px " + resourceSetColors[resourceSetId];
      return (
        <div className="CPU" style={style}>
          {fourHardwareThreads}
        </div>
      );
    } else {
      return (
        <div className="CPU" style={opacityStyle}>
          {fourHardwareThreads}
        </div>
      );
    }
  }
}
