// React Imports
import React, { Component } from "react";

// 3rd Party Package Imports
import Toast from "react-bootstrap/Toast";

//JSV Project Imports
import "../App.css";

export default class MyToast extends Component {
  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
    this.dismissedButtonPressed = this.dismissedButtonPressed.bind(this);
    this.state = {
      show: true
    };
  }
  toggle() {
    this.setState({ show: !this.state.show });
  }
  dismissedButtonPressed() {
    const index = this.props.index;
    this.props.dismiss(index);
  }
  render() {
    return (
      <Toast show={true} onClose={this.dismissedButtonPressed}>
        <Toast.Header>
          <strong className="mr-auto">{this.props.header}</strong>
        </Toast.Header>
        <Toast.Body>{this.props.message}</Toast.Body>
      </Toast>
    );
  }
}
