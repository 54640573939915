// React Imports
import React, { Component } from "react";

// 3rd Party Package Imports
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

// JSV Project Imports
import "../App.css";

export default class GridCell extends Component {
  state = {
    hover: false
  };

  onMouseEnter = () => {
    this.setState({ hover: true });
  };

  onMouseLeave = () => {
    this.setState({ hover: false });
  };

  render() {
    const { system, hostname, visible, nodeUsage } = this.props;

    if (visible === "enabled") {
      return (
        <div>
          <OverlayTrigger
            key={hostname}
            placement="top"
            overlay={
              <Tooltip className={"tooltip-top"} id={"tooltip-top"}>
                {hostname}
              </Tooltip>
            }
          >
            <div
              onMouseEnter={this.onMouseEnter}
              onMouseLeave={this.onMouseLeave}
              className={`element-${system} element-${hostname} ${visible} ${nodeUsage} ${
                this.state.hover ? "hover" : ""
              }`}
            ></div>
          </OverlayTrigger>
        </div>
      );
    } else {
      return (
        <div
          onMouseEnter={this.onMouseEnter}
          onMouseLeave={this.onMouseLeave}
          className={`element-${system} element-${hostname} ${visible} ${nodeUsage} ${
            this.state.hover ? "hover" : ""
          }`}
        ></div>
      );
    }
  }
}
