// React Imports
import React, { Component } from "react";

// JSV Project Imports
import "../App.css";

export default class SWT extends Component {
  render() {
    return <div>&emsp;&emsp;&emsp;{this.props.threadId}</div>;
  }
}
