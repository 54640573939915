// React Imports
import React, { Component } from "react";
import "../App.css";

// 3rd Party Package Imports
import "bootstrap/dist/css/bootstrap.min.css";

export default class MetadataItem extends Component {
  render() {
    const { item, value } = this.props;

    return (
      <div className="metadata-item">
        <nobr>
          {" "}
          <strong>{item}:</strong> {value}{" "}
        </nobr>
      </div>
    );
  }
}
