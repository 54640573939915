// React Imports
import React, { Component } from "react";

// JSV Project Imports
import "../App.css";

export default class Rank extends Component {
  render() {
    return <div> - Rank: {this.props.rankId}</div>;
  }
}
