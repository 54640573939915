// React Imports
import React, { Component } from "react";

// 3rd Party Package Imports
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSadTear } from "@fortawesome/free-solid-svg-icons";
import "bootstrap/dist/css/bootstrap.min.css";
import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

// JSV Project Imports
import "../App.css";

export default class BadRequest extends Component {
  render() {
    return (
      <Container>
        <Row>
          <Col></Col>
          <Col>
            <br />
            <span class="navbar-brand" style={{ fontSize: "xx-large" }}>
              <FontAwesomeIcon icon={faSadTear} /> Page not found.
            </span>
            <hr />
            <p>Sorry, we cant find what you're looking for.</p>
            <Button
              size="sm"
              variant="outline-secondary"
              href="https://jobstepviewer.olcf.ornl.gov"
              block
            >
              Go Back Home
            </Button>
          </Col>
          <Col></Col>
        </Row>
      </Container>
    );
  }
}
