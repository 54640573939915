// React Imports
import React, { Component } from "react";

// JSV Project Imports
import "../App.css";

// 3rd Party Package Imports
import "bootstrap/dist/css/bootstrap.min.css";
import { Nav, NavDropdown, Dropdown } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMicrochip } from "@fortawesome/free-solid-svg-icons";

export default class ActiveNodesDropdown extends Component {
  render() {
    var key = 0;
    let Items = [];
    let hostname = "";
    let data = this.props.data;
    let run = this.props.run;

    if (data) {
      var keys = Object.keys(data.compute_hosts);
      for (key in keys) {
        hostname = keys[key];
        Items.push(
          <NavDropdown.Item
            className="dropdown-link"
            href={"/"+this.props.system+"/" + run + "/" + hostname}
            eventKey={key}
          >
            {hostname}
          </NavDropdown.Item>
        );
      }
    }
    const CustomMenu = React.forwardRef(
      ({ children, className, "aria-labelledby": labeledBy }, ref) => {
        const [value, setValue] = React.useState();

        return (
          <div ref={ref} className={className} aria-labelledby={labeledBy}>
            <Form.Control
              autoFocus
              className="mx-3 my-2 w-auto"
              placeholder="Type to filter..."
              onChange={e => setValue(e.target.value)}
              value={value}
            />
            <ul className="list-unstyled">
              {React.Children.toArray(children).filter(
                child =>
                  !value || child.props.children.toLowerCase().startsWith(value)
              )}
            </ul>
          </div>
        );
      }
    );
    return (
      <Dropdown as={Nav.Item} id="active-nodes-dropdown">
        <Dropdown.Toggle as={Nav.Link}>
          <nobr>
            <FontAwesomeIcon icon={faMicrochip} /> Nodes Used
          </nobr>
        </Dropdown.Toggle>
        <Dropdown.Menu as={CustomMenu}>{Items}</Dropdown.Menu>
      </Dropdown>
    );
  }
}
