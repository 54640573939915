// React Imports
import React, { Component } from "react";

// 3rd Party Package Imports
import "bootstrap/dist/css/bootstrap.min.css";
import { Nav } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestion } from "@fortawesome/free-solid-svg-icons";

// JSV Project Imports
import ResourceSet from "../media/resource_set.png";
import "../App.css";

export default class Help extends Component {
  render() {
    function Help() {
      const [show, setShow] = React.useState(false);

      const handleClose = () => setShow(false);
      const handleShow = () => setShow(true);

      return (
        <>
          <Nav.Item className="navIcon">
            <Nav.Link onClick={handleShow}>
              <FontAwesomeIcon icon={faQuestion} /> Help / Legend
            </Nav.Link>
          </Nav.Item>

          <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
              <Modal.Title>Help / Legend</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <center>
                <img
                  src={ResourceSet}
                  width="auto"
                  height="275"
                  alt="Resource Set Legend"
                />

                <hr />

                <table class="tg">
                  <tr>
                    <th class="tg-wa1i">Process Notation</th>
                    <th class="tg-wa1i">Meaning</th>
                    <th class="tg-wa1i">Actions</th>
                  </tr>
                  <tr>
                    <td class="tg-nrix">#</td>
                    <td class="tg-cly1">Rank ID</td>
                    <td class="tg-0lax">N/A</td>
                  </tr>
                  <tr>
                    <td class="tg-nrix">#[#]</td>
                    <td class="cly1">Rank ID [ Thread ID ]</td>
                    <td class="tg-0lax">N/A</td>
                  </tr>
                  <tr>
                    <td class="tg-baqh">...</td>
                    <td class="tg-0lax">
                      Oversubscribed Hardware Thread <br /> (multiple ranks
                      and/or multiple threads)
                    </td>
                    <td class="tg-0lax">Hover to expand</td>
                  </tr>
                  <tr>
                    <td class="tg-baqh">+</td>
                    <td class="tg-0lax">
                      Rank ID ([ Thread ID ]) too long to display
                    </td>
                    <td class="tg-0lax">Hover to expand</td>
                  </tr>
                </table>
              </center>
              <hr />
              {/* Compute Node Legend Here */}
              <br />2 CPU cores is reserved for system processes on each Power9
              processor, and is not accessible to the end-user. For this reason,
              it is not included in the compute node diagram. This accounts for
              the Hardware Thread ID skip from 083 to 088 between Socket 0 and
              Socket 1, and why Hardware Thread IDs after 171 are not shown. (See{" "}
              <a href="https://docs.olcf.ornl.gov/systems/summit_user_guide.html#system-service-core-isolation">
                System Service Core Isolation
              </a>
              ).
              <hr />
              Please direct questions and bug reports to{" "}
              <span className="link">help@olcf.ornl.gov</span>.
              <hr />
            </Modal.Body>
          </Modal>
        </>
      );
    }

    return <Help />;
  }
}
