// React Imports
import React, { Component } from "react";

// 3rd Party Package Imports
import "bootstrap/dist/css/bootstrap.min.css";
import { Nav, Navbar, Alert, Button, Spinner } from "react-bootstrap";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowsAltH, faSquare } from "@fortawesome/free-solid-svg-icons";
import { Helmet } from "react-helmet";

// JSV Project Imports
import "./App.css";
import BadRequest from "./components/BadRequest";
import GridCell from "./components/GridCell";
import ActiveNodesDropdown from "./components/ActiveNodesDropdown";
import SystemMapLink from "./components/SystemMapLink";
import Help from "./components/Help";
import Metadata from "./components/Metadata";
import CPU from "./components/CPU";
import GPU from "./components/GPU";
import MyToast from "./components/MyToast";
import disableHosts from "./data/disableHosts.json";
import OLCFLogo from "./media/OLCF_official_color_10_26_15.png";
import PoweredBySlate from "./media/powered_by_slate.png";
import DOEScience from "./media/RGB_Black-Seal_Black-Mark_SC_Horizontal.png";
import SummitLogo from "./media/SUMMIT_LOGO_OFFICIAL_2017.png";

class App extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      runData: null,
      system: this.props.match.params.system,
      runId: this.props.match.params.run,
      hostname: this.props.match.params.hostname,
      path: this.props.location.pathname,
      requestFailed: null
    };
    this.warnings = [];
  }

  /*
   * Attempt to fetch the JSON data for a given runID.
   * A runID is a batch job ID followed by a job step ID.
   * For example, runID 12345-2 is batch job 12345, job step #2.
   */
  fetchRunData(runId, system) {
    var url = "";
    if(system === "ascent") url = "https://jsv-data-open.apps.onyx.ccs.ornl.gov/ascent/" + runId;
    else url = "https://jsv-data.apps.granite.ccs.ornl.gov/summit/" + runId;

    fetch(url)
      .then(response => {
        if (response.status === 404) {
          throw new Error("404: The requested URL was not found");
        }
        return response.json();
      })
      .then(jsonData => {
        this.setState({ runData: jsonData });
      })
      .catch(error => {
        console.error(
          "There has been a problem with your fetch operation:",
          error
        );
        this.setState({ requestFailed: true });
      });
  }

  componentDidMount() {
    this.fetchRunData(this.state.runId, this.state.system);
  }

  /*
   * Validates that the hostname provided by the URL react router
   * URL parameter :hostname is present in the run data.
   */
  hostnameDoesExist() {
    var hosts = [];
    if (this.state.hostname != null && this.state.runData != null) {
      hosts = Object.keys(this.state.runData.compute_hosts);
      if (hosts.indexOf(this.state.hostname) >= 0) return true;
      else return false;
    }
    return true;
  }

  render() {
    if (this.state.requestFailed === true || !this.hostnameDoesExist())
      return <BadRequest></BadRequest>;
    else {
      return (
        <div className="body">
          <Helmet>
            <title>Job Step Viewer ({this.state.runId})</title>
          </Helmet>

          <div id="page-container">
            <div id="content-wrap">
              <div className="navigationBar">
                <Navbar collapseOnSelect expand="md">
                  <img
                    src={OLCFLogo}
                    width="auto"
                    height="35"
                    alt="OLCF logo"
                  />
                  <a
                    href="https://jobstepviewer.olcf.ornl.gov"
                    style={{ paddingLeft: "20px" }}
                  >
                    <Navbar.Brand>Job Step Viewer</Navbar.Brand>
                  </a>
                  <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                  <Navbar.Collapse id="responsive-navbar-nav">
                    <Nav className="mr-auto">
                      <SystemMapLink run={this.state.runId} system={this.state.system}></SystemMapLink>
                      <ActiveNodesDropdown
                        data={this.state.runData}
                        run={this.state.runId}
                        system={this.state.system}
                      ></ActiveNodesDropdown>
                      <Metadata data={this.state.runData}></Metadata>
                    </Nav>
                    <Nav>
                      <Help></Help>
                    </Nav>
                  </Navbar.Collapse>
                </Navbar>
              </div>
              <Router>
                <div>
                  <Switch>
                    <Route
                      exact
                      path="/summit/:run"
                      render={props => (
                        <SystemMap
                          {...props}
                          jsonData={this.state.runData}
                          warnings={this.warnings}
                          system={this.state.system}
                        />
                      )}
                    />
                     <Route
                      exact
                      path="/ascent/:run"
                      render={props => (
                        <AscentMap
                          {...props}
                          jsonData={this.state.runData}
                          warnings={this.warnings}
                          system={this.state.system}
                        />
                      )}
                    />
                    <Route
                      path="/:system/:run/:hostname"
                      render={props => (
                        <ComputeNode {...props}
                          jsonData={this.state.runData}
                          system={this.state.system}
                        />
                      )}
                    />
                  </Switch>
                </div>
              </Router>
            </div>

            <footer className="site-footer">
              <div className="footer-left">
                <div>
                  <img
                    src={DOEScience}
                    width="auto"
                    height="35"
                    alt="Department of Energy Office of Science Logo"
                  />
                </div>
                <div>
                  <br /> Oak Ridge National Laboratory is managed <br />
                  by UT-Battelle for the US Department of Energy.
                </div>
              </div>
              <div className="footer-center">
                <a
                  href="https://doc.marble.ccs.ornl.gov/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {" "}
                  <img
                    src={PoweredBySlate}
                    width="auto"
                    height="40"
                    alt="Powered by Slate"
                  />{" "}
                </a>{" "}
              </div>
              <div className="footer-right">
                <a
                  href="https://docs.olcf.ornl.gov/systems/summit_user_guide.html"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {" "}
                  <img
                    src={SummitLogo}
                    width="auto"
                    height="40"
                    alt="Summit logo"
                  />{" "}
                </a>{" "}
              </div>
            </footer>
          </div>
        </div>
      );
    }
  }
}

const racks = ["a", "b", "c", "d", "e", "f", "g", "h"];
const cabinets = [
  "01",
  "02",
  "03",
  "04",
  "05",
  "06",
  "07",
  "08",
  "09",
  "10",
  "11",
  "12",
  "13",
  "14",
  "15",
  "16",
  "17",
  "18",
  "19",
  "20",
  "21",
  "22",
  "23",
  "24",
  "25",
  "26",
  "27",
  "28",
  "29",
  "30",
  "31",
  "32",
  "33",
  "34",
  "35",
  "36",
  "50"
];
const nid = [
  "01",
  "02",
  "03",
  "04",
  "05",
  "06",
  "07",
  "08",
  "09",
  "10",
  "11",
  "12",
  "13",
  "14",
  "15",
  "16",
  "17",
  "18"
];

class SystemMap extends Component {
  constructor(props) {
    super(props);
    this.state = {
      warnings: [],
      activeWarnings: [],
      backButtonPressed: false
    };
    this.unusedNodesWarning = {
      header: "Warning: Unused Compute Nodes",
      message:
        "This job step does not use all allocated nodes. If not launching other concurrent job steps, consider using `jsrun -r` to distribute resource sets across nodes."
    };
    this.dismissToast = this.dismissToast.bind(this);
    this.toggleShow = this.toggleShow.bind(this);
    this.runId = this.props.match.params.run;
    this.hiddenHosts = disableHosts.disableHosts;
    this.hostname = "";
    this.rows = [];
    this.errorFound = false;
  }

  componentDidMount() {
    if (this.props.jsonData != null) {
      this.setState({ activeWarnings: this.state.warnings.slice() });
    }
  }
  componentDidUpdate(prevProps) {
    if (this.props.jsonData !== prevProps.jsonData) {
      this.setState({ activeWarnings: this.state.warnings.slice() });
    }
  }

  /*
   * ToggleShow dismisses and displays active warnings.
   * Toasts are managed by two arrays:
   * 
   * 1. "warnings" manages which warnings were 
   *    generated by the system
   * 2. "activeWarnings" manages which warnings 
   *    are actively displayed on screen
   */
  toggleShow() {
    if (this.state.activeWarnings.length > 0)
      this.setState({ activeWarnings: [] });
    else this.setState({ activeWarnings: this.state.warnings.slice() });
  }

  /*
   * Takes the index of a specific toast and removes
   * it from the active warnings array
   */
  dismissToast(index) {
    var state = this.state;
    this.state.activeWarnings.splice(index, 1);
    this.setState(state);
  }

  /*
   * Responsible for creating grid cells. There are 3 types
   * of cells to be generated: 
   * 
   * 1. Hidden cells represent non-compute hosts and are not visible. 
   * 2. Unused hosts are hosts that are allocated but not used and are colored red
   * 3. Active hosts are hosts that are allocated and used and are colored green
   */
  createGridCells() {
    // Hide non-compute hosts
    if (this.isHostHidden(this.hostname))
      this.rows.push(<GridCell system = "summit" hostname={this.hostname} visible="disabled" />);

    // If host is in available_hosts (allocated) but not in compute_hosts (unused) 
    // make it red and disable it.
    else if (this.isHostAvailable(this.hostname) && !this.isHostUsed(this.hostname)) {
      this.rows.push(
        <GridCell
          system = "summit"
          hostname={this.hostname}
          visible="enabled"
          nodeUsage="unused"
        />
      );
      if (!this.errorFound) {
        this.state.warnings.push(this.unusedNodesWarning);
        this.errorFound = true;
      }
    }

    // If host is in available_hosts (allocated) and compute_hosts (used) 
    // make it green and enable it.
    else if (this.isHostUsed(this.hostname))
      this.rows.push(
        <Link to={"/summit/" + this.runId + "/" + this.hostname}>
          <GridCell
            system="summit"
            hostname={this.hostname}
            visible="enabled"
            nodeUsage="activated"
          />
        </Link>
      );

    // If host is not allocated, make it grey but provide no drill-down ability.
    else
      this.rows.push(<GridCell system="summit" hostname={this.hostname} visible="enabled" />);
    return false;
  }

  /* 
   * Takes a hostname string and returns true
   * if the hostname is among the available hosts
   */
  isHostAvailable(hostname) {
    return (
      this.props.jsonData &&
      this.props.jsonData.available_hosts.indexOf(hostname) >= 0
    );
  }

  /* 
   * Takes a hostname string and returns true
   * if the hostname is among the hidden hosts
   */
  isHostHidden(hostname) {
    return this.props.jsonData && this.hiddenHosts.indexOf(hostname) >= 0;
  }

  /* 
   * Takes a hostname string and returns true
   * if the hostname is among the active compute hosts
   */  
  isHostUsed(hostname) {
    var host;
    if (this.props.jsonData) {
      var hostnames = Object.keys(this.props.jsonData.compute_hosts);
      for (host in hostnames) {
        if (hostname === hostnames[host]) return true;
      }
    }
    return false;
  }

  render() {
    var buttonText = "Show/Hide Warnings";
    var toasts = [];
    if (this.props.jsonData) {
      this.rows = [];
      for (let rack = 0; rack < 8; rack++) {
        for (let id = 0; id < 18; id++) {
          for (let cabinet = 0; cabinet < 37; cabinet++) {
            this.hostname = racks[rack] + cabinets[cabinet] + "n" + nid[id];
            this.createGridCells();
          }
        }
      }
      for (let i = 0; i < this.state.activeWarnings.length; i++) {
        const warning = this.state.activeWarnings[i];
        toasts.push(
          <MyToast
            dismiss={this.dismissToast}
            header={warning.header}
            message={warning.message}
            index={i}
          ></MyToast>
        );
      }
    }
    if (this.state.warnings.length === 0) buttonText = "No Warnings";
    return (
      <div>
        <Loading
          system = {this.props.system}
          isLoading={this.props.jsonData}
          toggleShow={this.toggleShow}
          rows={this.rows}
          toasts={toasts}
          buttonText={buttonText}
        ></Loading>
      </div>
    );
  }
}

/*
 * Conditional logic that displays a spinner until
 * the run data can be fetched from the server
 */
const Loading = props => {
  const isLoading = props.isLoading;
  var disabled = false;
  if (props.buttonText === "No Warnings") disabled = true;
  if (!isLoading) {
    return (
      <div className="loading-spinner">
        <Spinner animation="border" role="status">
          <span className="sr-only">Loading...</span>
        </Spinner>
      </div>
    );
  }
  if(props.system === "summit"){
    return (
      <div>
        <Button
          className="show-hide-warnings"
          onClick={props.toggleShow}
          variant="light"
          size="sm"
          disabled={disabled}
        >
          {props.buttonText}
        </Button>

        <div className="exterior-wrapper">
          <div className="yAxis-label-div">
            <p className="yAxis-label">Row</p>
          </div>

          <div>
            <div className="wrapper">
              <div
                style={{
                  position: "absolute",
                  top: 65,
                  right: 10,
                  zIndex: 999
                }}
              >
                {props.toasts}
              </div>

              <div className="header"></div>
              <div className="leftBar">
                <div className="yAxis">a</div>
                <div className="yAxis"></div>
                <div className="yAxis">b</div>
                <div className="yAxis"></div>
                <div className="yAxis">c</div>
                <div className="yAxis"></div>
                <div className="yAxis">d</div>
                <div className="yAxis"></div>
                <div className="yAxis">e</div>
                <div className="yAxis"></div>
                <div className="yAxis">f</div>
                <div className="yAxis"></div>
                <div className="yAxis">g</div>
                <div className="yAxis"></div>
                <div className="yAxis">h</div>
                <div className="yAxis"></div>
              </div>
              <div className="rightBar"></div>
              <div className="footer">
                <div className="xAxis">01</div>
                <div className="xAxis">02</div>
                <div className="xAxis">03</div>
                <div className="xAxis">04</div>
                <div className="xAxis">05</div>
                <div className="xAxis">06</div>
                <div className="xAxis">07</div>
                <div className="xAxis">08</div>
                <div className="xAxis">09</div>
                <div className="xAxis">10</div>
                <div className="xAxis">11</div>
                <div className="xAxis">12</div>
                <div className="xAxis">13</div>
                <div className="xAxis">14</div>
                <div className="xAxis">15</div>
                <div className="xAxis">16</div>
                <div className="xAxis">17</div>
                <div className="xAxis">18</div>
                <div className="xAxis">19</div>
                <div className="xAxis">20</div>
                <div className="xAxis">21</div>
                <div className="xAxis">22</div>
                <div className="xAxis">23</div>
                <div className="xAxis">24</div>
                <div className="xAxis">25</div>
                <div className="xAxis">26</div>
                <div className="xAxis">27</div>
                <div className="xAxis">28</div>
                <div className="xAxis">29</div>
                <div className="xAxis">30</div>
                <div className="xAxis">31</div>
                <div className="xAxis">32</div>
                <div className="xAxis">33</div>
                <div className="xAxis">34</div>
                <div className="xAxis">35</div>
                <div className="xAxis">36</div>
                <div className="xAxis">50</div>
              </div>
              <div className="table">{props.rows}</div>
            </div>
            <center>
              <p className="xAxis">Cabinet</p>
            </center>
            <div className="map-legend-green map-legend-left">
              {" "}
              <FontAwesomeIcon icon={faSquare} /> Allocated, Used by job step{" "}
            </div>
            <div className="map-legend-red">
              {" "}
              <FontAwesomeIcon icon={faSquare} /> Allocated, Not used by job step{" "}
            </div>
            <div className="map-legend-size map-legend-left">
              {" "}
              <FontAwesomeIcon className="map-legend-grey" icon={faSquare} /> Not
              Allocated{" "}
            </div>
            <div className="map-legend-size">
              {" "}
              <FontAwesomeIcon
                className="map-legend-white"
                icon={faSquare}
              />{" "}
              Misc. (Not compute nodes){" "}
            </div>
            <div className="map-legend-size map-legend-hostname-scheme">
              {" "}
              Hostname Scheme: row[cabinet]n[01-18]{" "}
            </div>
          </div>
        </div>
      </div>
    );
  }
  else{
    return(
      <div>
      <Button
        className="show-hide-warnings"
        onClick={props.toggleShow}
        variant="light"
        size="sm"
        disabled={disabled}
      >
        {props.buttonText}
      </Button>

      <div className="exterior-wrapper">
        <div>
          <div className="wrapper">
            <div
              style={{
                position: "absolute",
                top: 65,
                right: 10,
                zIndex: 999
              }}
            >
              {props.toasts}
            </div>

            <div className="header"></div>
            <div className="ascent-table">{props.rows}</div>
          </div>
          <div className="map-legend-green map-legend-left">
            {" "}
            <FontAwesomeIcon icon={faSquare} /> Allocated, Used by job step{" "}
          </div>
          <div className="map-legend-red">
            {" "}
            <FontAwesomeIcon icon={faSquare} /> Allocated, Not used by job step{" "}
          </div>
          <div className="map-legend-size map-legend-left">
            {" "}
            <FontAwesomeIcon className="map-legend-grey" icon={faSquare} /> Not
            Allocated{" "}
          </div>
          <div className="map-legend-size">
            {" "}
            <FontAwesomeIcon
              className="map-legend-white"
              icon={faSquare}
            />{" "}
            Misc. (Not compute nodes){" "}
          </div>
          <div className="map-legend-size map-legend-hostname-scheme">
            {" "}
            Hostname Scheme: row[cabinet]n[01-18]{" "}
          </div>
        </div>
      </div>
    </div>
    );
  }
};

class AscentMap extends Component {
  constructor(props) {
    super(props);
    this.state = {
      warnings: [],
      activeWarnings: [],
      backButtonPressed: false
    };
    this.unusedNodesWarning = {
      header: "Warning: Unused Compute Nodes",
      message:
        "This job step does not use all allocated nodes. If not launching other concurrent job steps, consider using `jsrun -r` to distribute resource sets across nodes."
    };
    this.dismissToast = this.dismissToast.bind(this);
    this.toggleShow = this.toggleShow.bind(this);
    this.runId = this.props.match.params.run;
    this.hiddenHosts = disableHosts.disableHosts;
    this.hostname = "";
    this.rows = [];
    this.ascent_hosts = [
      "h49n01",
      "h49n02",
      "h49n03",
      "h49n04",
      "h49n05",
      "h49n06",
      "h49n07",
      "h49n08",
      "h49n09",
      "h49n10",
      "h49n11",
      "h49n12",
      "h49n13",
      "h49n14",
      "h49n15",
      "h49n16",
      "h49n17",
      "h49n18"
    ];
    this.errorFound = false;
  }

  componentDidMount() {
    if (this.props.jsonData != null) {
      this.setState({ activeWarnings: this.state.warnings.slice() });
    }
  }
  componentDidUpdate(prevProps) {
    if (this.props.jsonData !== prevProps.jsonData) {
      this.setState({ activeWarnings: this.state.warnings.slice() });
    }
  }

  /*
   * ToggleShow dismisses and displays active warnings.
   * Toasts are managed by two arrays:
   * 
   * 1. "warnings" manages which warnings were 
   *    generated by the system
   * 2. "activeWarnings" manages which warnings 
   *    are actively displayed on screen
   */
  toggleShow() {
    if (this.state.activeWarnings.length > 0)
      this.setState({ activeWarnings: [] });
    else this.setState({ activeWarnings: this.state.warnings.slice() });
  }

  /*
   * Takes the index of a specific toast and removes
   * it from the active warnings array
   */
  dismissToast(index) {
    var state = this.state;
    this.state.activeWarnings.splice(index, 1);
    this.setState(state);
  }

  /*
   * Responsible for creating grid cells. There are 3 types
   * of cells to be generated: 
   * 
   * 1. Hidden cells represent non-compute hosts and are not visible. 
   * 2. Unused hosts are hosts that are allocated but not used and are colored red
   * 3. Active hosts are hosts that are allocated and used and are colored green
   */
  createGridCells() {
    // Hide non-compute hosts
    if (this.isHostHidden(this.hostname))
      this.rows.push(<GridCell system="ascent" hostname={this.hostname} visible="disabled" />);

    // If host is in available_hosts (allocated) but not in compute_hosts (unused) 
    // make it red and disable it.
    else if (this.isHostAvailable(this.hostname) && !this.isHostUsed(this.hostname)) {
      this.rows.push(
        <GridCell
          system="ascent"
          hostname={this.hostname}
          visible="enabled"
          nodeUsage="unused"
        />
      );
      if (!this.errorFound) {
        this.state.warnings.push(this.unusedNodesWarning);
        this.errorFound = true;
      }
    }

    // If host is in available_hosts (allocated) and compute_hosts (used) 
    // make it green and enable it.
    else if (this.isHostUsed(this.hostname))
      this.rows.push(
        <Link to={"/ascent/" + this.runId + "/" + this.hostname}>
          <GridCell
            system="ascent"
            hostname={this.hostname}
            visible="enabled"
            nodeUsage="activated"
          />
        </Link>
      );

    // If host is not allocated, make it grey but provide no drill-down ability.
    else
      this.rows.push(<GridCell system="ascent" hostname={this.hostname} visible="enabled" />);
    return false;
  }

  /* 
   * Takes a hostname string and returns true
   * if the hostname is among the available hosts
   */
  isHostAvailable(hostname) {
    return (
      this.props.jsonData &&
      this.props.jsonData.available_hosts.indexOf(hostname) >= 0
    );
  }

  /* 
   * Takes a hostname string and returns true
   * if the hostname is among the hidden hosts
   */
  isHostHidden(hostname) {
    return this.props.jsonData && this.hiddenHosts.indexOf(hostname) >= 0;
  }

  /* 
   * Takes a hostname string and returns true
   * if the hostname is among the active compute hosts
   */  
  isHostUsed(hostname) {
    var host;
    if (this.props.jsonData) {
      var hostnames = Object.keys(this.props.jsonData.compute_hosts);
      for (host in hostnames) {
        if (hostname === hostnames[host]) return true;
      }
    }
    return false;
  }

  render() {
    var buttonText = "Show/Hide Warnings";
    var toasts = [];
    if (this.props.jsonData) {
      this.rows = [];
      for (let index = 0; index < this.ascent_hosts.length; index++) {
        this.hostname = this.ascent_hosts[index];
        this.createGridCells();
      }
      for (let i = 0; i < this.state.activeWarnings.length; i++) {
        const warning = this.state.activeWarnings[i];
        toasts.push(
          <MyToast
            dismiss={this.dismissToast}
            header={warning.header}
            message={warning.message}
            index={i}
          ></MyToast>
        );
      }
    }
    if (this.state.warnings.length === 0) buttonText = "No Warnings";
    return (
      <div>
        <Loading
          isLoading={this.props.jsonData}
          toggleShow={this.toggleShow}
          rows={this.rows}
          toasts={toasts}
          buttonText={buttonText}
        ></Loading>
      </div>
    );
  }
}

class ComputeNode extends Component {
  constructor(props) {
    super(props);
    this.state = {
      warnings: [],
      activeWarnings: []
    };
    this.gpuWarning = {
      header: "Warning: No GPUs",
      message:
        "This job step is not using NVIDIA Volta V100 GPUs. Consider using them for increased performance."
    };
    this.oversubscribedWarning = {
      header: "Warning: Oversubscribed Hardware Threads",
      message:
        "Hardware Thread oversubscription may lead to decreased performance. Check task and thread binding."
    };
    this.dismissToast = this.dismissToast.bind(this);
    this.toggleShow = this.toggleShow.bind(this);
    this.hostname = this.props.match.params.hostname;
    this.data = this.props.jsonData;
  }

  componentDidUpdate(prevProps) {
    if (this.props.jsonData !== prevProps.jsonData) {
      this.getOversubscribedThreadWarnings(this.hostname);
      this.getUnusuedGPUWarnings(this.hostname);
      this.setState({ activeWarnings: this.state.warnings.slice() });
    }
  }

  componentDidMount() {
    if (this.props.jsonData != null) {
      this.getOversubscribedThreadWarnings(this.hostname);
      this.getUnusuedGPUWarnings(this.hostname);
      this.setState({ activeWarnings: this.state.warnings.slice() });
    }
  }

  /*
   * Takes a hostname and generates a warning if there
   * is a case of hardware thread oversubscription.
   */
  getOversubscribedThreadWarnings(hostname) {
    var hwt, numProcs, numThreads;

    for (hwt in this.data.compute_hosts[hostname].processes) {
      numProcs = this.getNumProcs(hwt, hostname);
      numThreads = this.getNumThreads(hwt, hostname);
      if (numProcs > 1 || numThreads > 1) {
        this.state.warnings.push(this.oversubscribedWarning);
        break;
      }
    }
  }
  /*
   * Takes a hostname and generates a warning if there
   * are unused GPUs.
   */
  getUnusuedGPUWarnings(hostname) {
    var set;
    for (set in this.data.compute_hosts[hostname].resource_sets) {
      var socket0 = this.data.compute_hosts[hostname].resource_sets[set]
        .socket0;
      var socket1 = this.data.compute_hosts[hostname].resource_sets[set]
        .socket1;
      if (socket0.gpus.length + socket1.gpus.length === 0) {
        this.state.warnings.push(this.gpuWarning);
        break;
      }
    }
  }

  /*
   * Takes a hostname and an index to a specific hardware thread and
   * returns the number of processes on that hardware thread.
   */
  getNumProcs(hwt, hostname) {
    if (hwt === null) return 0;
    return this.data.compute_hosts[hostname].processes[hwt].ranks.length;
  }

  /*
   * Takes a hostname and an index to a specific hardware thread and
   * returns the number of software threads on that hardware thread.
   */
  getNumThreads(hwt, hostname) {
    var rank;

    if (hwt == null) return 0;
    if (this.data.openmp === false) return 0;
    for (rank in this.data.compute_hosts[hostname].processes[hwt].ranks) {
      return this.data.compute_hosts[hostname].processes[hwt].ranks[rank]
        .software_threads.length;
    }
  }

  /*
   * ToggleShow dismisses and displays active warnings.
   * Toasts are managed by two arrays:
   * 
   * 1. "warnings" manages which warnings were 
   *    generated by the system
   * 2. "activeWarnings" manages which warnings 
   *    are actively displayed on screen
   */
  toggleShow() {
    if (this.state.activeWarnings.length > 0)
      this.setState({ activeWarnings: [] });
    else this.setState({ activeWarnings: this.state.warnings.slice() });
  }

  /*
   * Takes the index of a specific toast and removes
   * it from the active warnings array.
   */ 
  dismissToast(index) {
    var state = this.state;
    this.state.activeWarnings.splice(index, 1);
    this.setState(state);
  }

  /*
   * Determine which resource set (if any) a given
   * physical core is in.
   */
  getCpuResourceSet(cpuId, data, hostname) {
    let host;
    let set;
    if (data) {
      for (host in data.compute_hosts) {
	if(host !== hostname) {
	  continue;
	}
        for (set in data.compute_hosts[host].resource_sets) {
          var cpus = data.compute_hosts[host].resource_sets[
            set
          ].socket0.cpus.concat(
            data.compute_hosts[host].resource_sets[set].socket1.cpus
          );
          if (cpus.indexOf(normalizeCpuId(cpuId)) >= 0) {
            return set;
          }
        }
      }
      return -1;
    }
  }

  /*
   * Determine which resource set (if any) a given
   * GPU is in.
   */
  getGpuResourceSet(gpuId, data, hostname) {
    let host;
    let setId;

    gpuId = gpuId + ""; //gpuId stored as string in json
    if (data) {
      for (host in data.compute_hosts) {
	if(host !== hostname) {
	  continue;
	}
	console.log(hostname);
        for (setId in data.compute_hosts[host].resource_sets) {
          var gpus = data.compute_hosts[host].resource_sets[
            setId
          ].socket0.gpus.concat(
            data.compute_hosts[host].resource_sets[setId].socket1.gpus
          );
          if (gpus.indexOf(gpuId) >= 0) {
            return setId;
          }
        }
      }
      return -1;
    }
  }

  /* Checks if nvme is enabled */
  getNVME(data) {
    if (data) {
      let nvme_bool = data.nvme;
      if (nvme_bool === true) {
        return "nvme nvme-enabled";
      } else {
        return "nvme nvme-disabled";
      }
    }
  }

  /* Gets user jsrun command */
  getJsrunLine(data) {
    if (data) {
      return data.jsrun_line;
    }
  }

  render() {
    let i = 0;
    let cpuZone0 = [];
    let cpuZone1 = [];
    let gpuZone0 = [];
    let gpuZone1 = [];
    let nvme = [];
    let resourceSetId;
    let hostname = this.hostname;
    this.data = this.props.jsonData;
    let data = this.data;
    let jsrunLine = this.getJsrunLine(data);
    let nvme_availability = this.getNVME(data);
    var toasts = [];
    var buttonText = "Show/Hide Warnings";
    var disabled = false;
    let systemString = "Summit";
    
    if(this.props.system === "ascent") systemString = "Ascent";

    if (data) {
      if (nvme_availability.includes("enabled")) {
        nvme.push(
          <div className={nvme_availability}>
            {" "}
            <p className="nvme-text">
              <strong>enabled</strong>
            </p>{" "}
            NVMe
            <br />
            (1.6TB)
          </div>
        );
      } else {
        nvme.push(
          <div className={nvme_availability}>
            {" "}
            <p className="nvme-text">
              <strong>disabled</strong>
            </p>{" "}
            NVMe
            <br />
            (1.6TB)
          </div>
        );
      }
    }

    // Create socket 0
    for (i = 0; i < 21; i++) {
      resourceSetId = this.getCpuResourceSet(i, data, hostname);
      cpuZone0.push(
        <CPU
          cpuId={i}
          resourceSetId={resourceSetId}
          data={this.data}
          hostname={this.hostname}
          warnings={this.state.warnings}
        />
      );
    }

    for (i = 0; i < 3; i++) {
      resourceSetId = this.getGpuResourceSet(i, data, hostname);
      gpuZone0.push(<GPU gpuId={i} resourceSetId={resourceSetId} />);
    }

    // Create socket 1
    for (i = 22; i < 43; i++) {
      resourceSetId = this.getCpuResourceSet(i - 1, data, hostname);
      cpuZone1.push(
        <CPU
          cpuId={i}
          resourceSetId={resourceSetId}
          data={this.data}
          hostname={this.hostname}
          warnings={this.state.warnings}
        />
      );
    }

    for (i = 3; i < 6; i++) {
      resourceSetId = this.getGpuResourceSet(i, data, hostname);
      gpuZone1.push(<GPU gpuId={i} resourceSetId={resourceSetId} />);
    }

    if (this.props.jsonData) {
      for (i = 0; i < this.state.activeWarnings.length; i++) {
        const warning = this.state.activeWarnings[i];
        toasts.push(
          <MyToast
            dismiss={this.dismissToast}
            header={warning.header}
            message={warning.message}
            index={i}
          ></MyToast>
        );
      }
    }
    if (this.state.warnings.length === 0) {
      buttonText = "No Warnings";
      disabled = true;
    }

    if (this.state.warnings.length === 0) {
      buttonText = "No Warnings";
      disabled = "true";
    }

    return (
      <div>
        <NodeLoading
          isLoading={this.props.jsonData}
          toggleShow={this.toggleShow}
          toasts={toasts}
          buttonText={buttonText}
          hostname={hostname}
          jsrunLine={jsrunLine}
          gpuZone0={gpuZone0}
          gpuZone1={gpuZone1}
          cpuZone0={cpuZone0}
          cpuZone1={cpuZone1}
          nvme={nvme}
          systemString={systemString}
        ></NodeLoading>
      </div>
    );
  }
}
const NodeLoading = props => {
  const isLoading = props.isLoading;
  var disabled = false;
  if (props.buttonText === "No Warnings") disabled = true;
  if (!isLoading) {
    return (
      <div className="loading-spinner">
        <Spinner animation="border" role="status">
          <span className="sr-only">Loading...</span>
        </Spinner>
      </div>
    );
  }
  return(
        <div>
        <Button
          className="show-hide-warnings"
          onClick={props.toggleShow}
          variant="light"
          size="sm"
          disabled={props.disabled}
        >
          {props.buttonText}
        </Button>

        <div className="nodeView">
          <div
            style={{
              position: "absolute",
              top: 65,
              right: 10,
              zIndex: 999
            }}
          >
            {props.toasts}
          </div>

          <Alert className="nodeView-Alert" variant="light">
            <Alert.Heading>
              <div>
                {props.systemString} Compute Node <strong>({props.hostname})</strong>
              </div>

            </Alert.Heading>
            <p>IBM Power System AC922</p>
            <hr />
            <center>
              <p className="small-text">{props.jsrunLine}</p>
            </center>
            <hr />
          </Alert>
          <div className="node">
            <div className="transfer">
              <div className="Ttext">X-bus</div>
              <div className="Ticon">
                <FontAwesomeIcon icon={faArrowsAltH} />
              </div>
            </div>
            <div className="socket0">
              <div className="socket-label-left">Socket 0</div>
              <div className="memory0">
                Memory
                <br />
                (256GB)
              </div>
              <div className="gpuZone0">{props.gpuZone0}</div>
              <div className="cpuZone0">{props.cpuZone0}</div>
            </div>
            <div className="socket1">
              <div className="socket-label-right">Socket 1</div>
              <div className="memory1">
                Memory
                <br />
                (256GB)
              </div>
              <div className="gpuZone1">{props.gpuZone1}</div>
              <div className="cpuZone1">{props.cpuZone1}</div>
            </div>
            {props.nvme}
          </div>
        </div>
      </div>
  );
}
/* Ensures that physical core IDs have leading zeros. */
function normalizeCpuId(cpuId) {
  cpuId = "0" + cpuId;
  return cpuId.substring(cpuId.length - 2, cpuId.length);
}

export default App;
