// React Imports
import React, { Component } from "react";

// 3rd Party Package Imports
import "bootstrap/dist/css/bootstrap.min.css";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";

// JSV Project Imports
import HWTPopover from "./HWTPopover";
import Rank from "./Rank";
import SWT from "./SWT";
import "../App.css";

export default class HardwareThread extends Component {
  constructor(props) {
    super(props);
    this.data = null;
    this.cpuId = this.props.cpuId;
    this.threadId = "";
    this.hostname = "";
    this.opacityStyle = {
      opacity: `0.3`
    };
    this.smtOpacityStyle = {
      opacity: `0`
    };
  }
  // Returns the number of processes on a specific hardware thread
  getNumProcs() {
    var hwt = this.getHardwareThreadIndex();
    if (hwt === null) return 0;
    return this.data.compute_hosts[this.hostname].processes[hwt].ranks.length;
  }

  // Returns the number of software threads on a specific hardware thread
  getNumThreads() {
    var rank;
    var hwt = this.getHardwareThreadIndex();

    if (hwt == null) return 0;
    if (this.data.openmp === false) return 0;
    for (rank in this.data.compute_hosts[this.hostname].processes[hwt].ranks) {
      return this.data.compute_hosts[this.hostname].processes[hwt].ranks[rank]
        .software_threads.length;
    }
  }

  // Creates a formated list of processes and software threads to be rendered on screen
  // when a user hovers over a hardwear thread
  createFormatedList() {
    var rank, thread;
    var formatedlist = [];
    var hwt = this.getHardwareThreadIndex();
    for (rank in this.data.compute_hosts[this.hostname].processes[hwt].ranks) {
      formatedlist.push(
        <Rank
          rankId={
            this.data.compute_hosts[this.hostname].processes[hwt].ranks[rank]
              .rank
          }
        />
      );
      if (this.getNumThreads() > 0) {
        formatedlist.push(<div>&emsp;- Thread(s):</div>);
        for (thread in this.data.compute_hosts[this.hostname].processes[hwt]
          .ranks[rank].software_threads) {
          formatedlist.push(
            <SWT
              threadId={
                this.data.compute_hosts[this.hostname].processes[hwt].ranks[
                  rank
                ].software_threads[thread]
              }
            />
          );
        }
      }
    }
    return formatedlist;
  }

  // Searches the json for a specific hardwear thread and returns
  // its index if found or null if not found
  getHardwareThreadIndex() {
    var hwt;
    for (hwt in this.data.compute_hosts[this.hostname].processes) {
      if (
        this.data.compute_hosts[this.hostname].processes[hwt]
          .hardware_thread === this.threadId
      ) {
        return hwt;
      }
    }
    return null;
  }

  // Checks to see if a process ID wont fit in the hardware thread UI
  rankDoesOverflow(hwt) {
    if (
      this.data.compute_hosts[this.hostname].processes[hwt].ranks[0].rank
        .length <= 3
    )
      return false;
    else return true;
  }

  // Returns hardware threads to be rendered in the UI
  buildThreads() {
    var hwt = this.getHardwareThreadIndex();
    var numProcs = this.getNumProcs();
    var numThreads = this.getNumThreads();
    var popoverPlacement = "right";

    if (this.cpuId > 20) popoverPlacement = "left";

    if (this.props.index >= this.data.smt_mode)
      return (
        <div className={"HardwareThread"} style={this.smtOpacityStyle}>
          {this.threadId}
        </div>
      );
    //hardware thread has no proccesses
    else if (hwt === null)
      return (
        <div className={"HardwareThread"} style={this.opacityStyle}>
          {this.threadId}
        </div>
      );
    //one rank no threads: #
    else if (numProcs === 1 && numThreads === 0) {
      if (this.rankDoesOverflow(hwt))
        return (
          <OverlayTrigger
            trigger="hover"
            placement={popoverPlacement}
            overlay={
              <HWTPopover
                formatedlist={this.createFormatedList()}
                threadId={this.threadId}
              />
            }
          >
            <div className={"HardwareThread"}>+</div>
          </OverlayTrigger>
        );
      else
        return (
          <div className={"HardwareThread"}>
            {
              this.data.compute_hosts[this.hostname].processes[hwt].ranks[0]
                .rank
            }
          </div>
        );
    }
    //one rank one thread: #[#]
    else if (numProcs === 1 && numThreads === 1) {
      if (this.rankDoesOverflow(hwt))
        return (
          <OverlayTrigger
            trigger="hover"
            placement={popoverPlacement}
            overlay={
              <HWTPopover
                formatedlist={this.createFormatedList()}
                threadId={this.threadId}
              />
            }
          >
            <div className={"HardwareThread"}>+</div>
          </OverlayTrigger>
        );
      else
        return (
          <div className={"HardwareThread"}>
            {
              this.data.compute_hosts[this.hostname].processes[hwt].ranks[0]
                .rank
            }
            [
            {
              this.data.compute_hosts[this.hostname].processes[hwt].ranks[0]
                .software_threads[0]
            }
            ]
          </div>
        );
    }
    //multiple ranks or multiple threads
    else if (numProcs > 1 || numThreads > 1) {
      return (
        <OverlayTrigger
          trigger="hover"
          placement={popoverPlacement}
          overlay={
            <HWTPopover
              formatedlist={this.createFormatedList()}
              threadId={this.threadId}
            />
          }
        >
          <div className={"HardwareThread"}>...</div>
        </OverlayTrigger>
      );
    } else return <div className={"HardwareThread"}>{this.threadId}</div>;
  }

  render() {
    this.data = this.props.data;
    this.threadId = this.props.threadID;
    this.hostname = this.props.hostname;
    var threadID = "000" + this.threadId;
    this.threadId = threadID.substring(threadID.length - 3, threadID.length);

    if (this.data) return this.buildThreads();
    else return <div className={"HardwareThread"}>{this.threadId}</div>;
  }
}
