// React Imports
import React, { Component } from "react";

// 3rd Party Package Imports
import "bootstrap/dist/css/bootstrap.min.css";
import { Nav } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfo } from "@fortawesome/free-solid-svg-icons";

// JSV Project Imports
import MetadataItem from "./MetadataItem";
import "../App.css";

export default class Metadata extends Component {
  render() {
    if (this.props.data) {
      let data = this.props.data;
      var metadata = [];
      var timestamp = data.timestamp;
      var smt_mode = data.smt_mode;
      var lsf_version = data.lsf_version;
      var job_id = data.job_id;
      var job_step_id = data.job_step_id;
      var jsrun_version = data.jsrun_version;

      metadata.push(<MetadataItem item="Job ID" value={job_id}></MetadataItem>);
      metadata.push(
        <MetadataItem item="Job Step ID" value={job_step_id}></MetadataItem>
      );
      metadata.push(
        <MetadataItem item="Start Time" value={timestamp}></MetadataItem>
      );
      metadata.push(
        <MetadataItem item="SMT Level" value={smt_mode}></MetadataItem>
      );
      metadata.push(
        <MetadataItem item="LSF Version" value={lsf_version}></MetadataItem>
      );
      metadata.push(
        <MetadataItem item="jsrun Version" value={jsrun_version}></MetadataItem>
      );
    }

    function Metadata() {
      const [show, setShow] = React.useState(false);
      const handleClose = () => setShow(false);
      const handleShow = () => setShow(true);

      return (
        <div>
          <Nav.Item className="navIcon">
            <Nav.Link onClick={handleShow}>
              <FontAwesomeIcon icon={faInfo} /> Job Info
            </Nav.Link>
          </Nav.Item>

          <Modal show={show} dialogClassName="modal-50w" onHide={handleClose}>
            <Modal.Header closeButton>
              <Modal.Title>Job Information</Modal.Title>
            </Modal.Header>
            <Modal.Body style={{ overflowY: "auto" }}>
              <p>{metadata}</p>
            </Modal.Body>
          </Modal>
        </div>
      );
    }

    return <Metadata />;
  }
}
