// React Imports
import React, { Component } from "react";

// 3rd Party Package Imports
import "bootstrap/dist/css/bootstrap.min.css";
import Popover from "react-bootstrap/Popover";

// JSV Project Imports
import "../App.css";

export default class HWTPopover extends Component {
  render() {
    var formatedlist = this.props.formatedlist;
    var threadId = this.props.threadId;

    return (
      <Popover {...this.props} id="popover-basic">
        <Popover.Title as="h3">Hardware Thread: {threadId}</Popover.Title>
        <Popover.Content>{formatedlist}</Popover.Content>
      </Popover>
    );
  }
}
