// React Imports
import React, { Component } from "react";

// 3rd Party Package Imports
import "bootstrap/dist/css/bootstrap.min.css";
import { Nav } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTh } from "@fortawesome/free-solid-svg-icons";

// JSV Project Imports
import "../App.css";

export default class SystemMapLink extends Component {
  render() {
    return (
      <Nav.Item>
        <Nav.Link href={"/"+ this.props.system + "/" + this.props.run}>
          <nobr>
            <FontAwesomeIcon icon={faTh} /> System Map
          </nobr>
        </Nav.Link>
      </Nav.Item>
    );
  }
}
