// React Imports
import React, { Component } from "react";

// 3rd Party Package Imports
import "bootstrap/dist/css/bootstrap.min.css";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { ReactSVG } from "react-svg";
import usageTerminal from "../media/usage.svg";

export default class NotFound extends Component {
  render() {
    return (
      <Container>
        <Row>
          <Col></Col>
          <Col>
            <br />
            <span class="navbar-brand" style={{ fontSize: "xx-large" }}>
              Job Step Viewer
            </span>
            <hr />
            <p>
              Job Step Viewer provides a graphical view of an application's
              runtime layout on Summit.
            </p>
            <p>
              <strong> 1) </strong> Load the <code>job-step-viewer</code>{" "}
              module.{" "}
            </p>
            <p>
              <strong> 2) </strong> Test out a <code>jsrun</code> line by
              itself, or provide an executable as normal.{" "}
            </p>
            <p>
              <strong> 3) </strong> Visit the provided URL.
            </p>
            <p style={{ fontSize: "x-small" }}>
              Try clicking the link generated in the demo below! (or{" "}
              <a href="/summit/871957-1">
                click here
              </a>
              )
            </p>
            <div>
              <ReactSVG src={usageTerminal} />
            </div>
          </Col>
          <Col></Col>
        </Row>
      </Container>
    );
  }
}
